<template>
    <div class="WorkOrder">
        <div class="new-container">
            <div class="header-search">
                <div class="flex" v-if="treeData.length != 0">
                    <div class="classify-down">
                        <img src="https://smart-resource.sikegroup.com/%E4%B8%AA%E4%BA%BA.png" alt="">
                        <div class="select-name">{{_selectedKeys}}</div>
                        <a-icon class="dow-icon" type="caret-down" />
                        <a-select class="search-select-list" @change="_treeSelect">
                            <a-select-option v-for="(item,index) in treeData[0].children" :value="item.value">{{item.title}} <span style="margin-left: 5px" :style="index < 2 ? 'color: #2994FF' :''">{{item.number}}</span></a-select-option>
                        </a-select>
                    </div>

                    <div class="classify-down" style="width: 130px;position: relative" @click="screenModal = true">
                        <img :src="_isScreen() ? 'https://smart-resource.sikegroup.com/%E7%AD%9B%E9%80%89%E5%90%8E.png' : 'https://smart-resource.sikegroup.com/%E7%AD%9B%E9%80%89%E5%89%8D.png'" alt="">
                        <div class="select-name select-label" style="line-height: normal" :style="_isScreen() ? 'color: #2994FF' : ''" @click.stop="_isScreen() ? _closeScreen() : screenModal = true">{{_isScreen() ? '取消筛选' : '筛选'}}</div>
                        <a-icon class="dow-icon" :type="screenModal ? 'caret-up' : 'caret-down'" />
                        <!--筛选框-->
                        <div class="screen-modal" v-if="screenModal">
                            <div class="flex screen-item">
                                <div class="screen-label">状态：</div>
                                <div class="screen-content">
                                    <a-button size="small" v-for="(item,index) in baseMsg.StatusText" :type="screenStatus == index ? 'primary' : ''" @click.stop="screenStatus = index">{{item.name}}</a-button>
                                </div>
                            </div>

                            <div class="flex screen-item">
                                <div class="screen-label">程度：</div>
                                <div class="screen-content">
                                    <a-button size="small" v-for="(item,index) in baseMsg.LevelText" :type="screenLevel == index ? 'primary' : ''" @click.stop="screenLevel = index">{{item.name}}</a-button>
                                </div>
                            </div>

                            <div class="flex screen-item">
                                <div class="screen-label">创建人：</div>
                                <div class="screen-content">
                                    <person v-bind="screenInfo" @getData="_getScreenInfo" style="width: 80%;margin-left: 5px;overflow: hidden"></person>
                                </div>
                            </div>

                            <div class="flex screen-item">
                                <div class="screen-label">日期：</div>
                                <div class="screen-content">
                                    <a-range-picker style="width: 80%;margin-left: 5px" @change="_screeDate"/>
                                </div>
                            </div>

                            <div class="modal-footer-2">
                                <a-button @click.stop="screenModal = false">取消</a-button>
                                <a-button type="primary" @click.stop="_screenConfig">确定</a-button>
                            </div>
                        </div>
                    </div>

                    <div class="classify-down" style="width: 240px;">
                        <span class="search-select-icon"><a-icon type="search" /></span>
                        <a-input class="search-input" placeholder="工单标题/工单号" allow-clear v-model="KeyWord" @blur="_infoData()" @pressEnter="_infoData()" @change="_closeText"/>
                    </div>
                </div>
                <div class="flex">
                    <div class="classify-down skip-set-btn" @click="_skipSkim" v-if="userInfo.uid == '2fdaaf510ce8b1d72ebe9d2eef975a5d'">
                        <span class="search-select-icon"><a-icon type="setting" /></span>
                        <div class="select-name" style="color: #666;margin-left: 10px">设置</div>
                    </div>

                    <div class="classify-down skip-add-btn" style="margin-right: 0" @click="_addModel">
                        <span class="search-select-icon"><a-icon type="plus" style="color: #fff;"/></span>
                        <div class="select-name" style="color: #fff;margin-left: 10px">新建</div>
                    </div>
                </div>
            </div>
            <div class="list-content">
                <div class="list-item" v-for="(item,index) in tableTable" @click="_clickTable(item)">
                    <!--标题信息-->
                    <div class="order-base-msg">
                        <div class="order-title">
                            <a-tag style="padding:1px 9px" :color="_statusColor(item.StatusText)">{{item.StatusText}}</a-tag>
                            <a-tag style="margin-right: 0;padding:1px 9px" :color="_weightColor(item.Level)">{{item.Level}}</a-tag>
                            {{item.Name}}
                        </div>
                        <div class="created-msg">
                            <div class="created-person-msg">
                                <img :src="item.CreateUserAvatar" alt="">
                                <span>{{item.CreateUserName}}</span>
                            </div>
                            <div class="created-date">{{item.CreateTime}}</div>
                            <div class="created-date">{{item.Category}}</div>
                        </div>
                    </div>
                    <!--指派人，抄送人-->
                    <div class="flex headPortrait" style="width: auto">
                        <div class="itemPortrait" v-for="(tab,tabIndex) in item.ApproveUserList" v-show="tabIndex < 5 && tab.icon" style="margin-bottom: 0;margin-right: 10px">
                            <a-popover placement="topLeft">
                                <template slot="content">
                                    <div class="flex" style="padding-right: 20px;">
                                        <img class="approve-icon" :src="tab.icon" alt="https://smart-resource.sikegroup.com/380b87ac3f8d75ddc2263b6867f0eb41.png">
                                        <div>
                                            <div class="approve-name">{{tab.title}} <span>{{tab.KeyPostName}}</span></div>
                                            <div class="approve-post">{{tab.ParentName}}</div>
                                        </div>
                                    </div>
                                </template>
                                <div style="display: inline-block;position: relative;">
                                    <img class="img" :src="tab.icon || tab.Avatar" alt="" style="margin-bottom: 0"/>
                                    <i class="iconfont" style="color: #FF0000" v-if="tab.Status == 6">&#xe66e;</i>
                                    <i class="iconfont" style="color: #1890FF" v-else-if="tab.Status == 3 || tab.Status == 2">&#xe671;</i>
                                    <i class="iconfont" style="color: #52C41A" v-else-if="tab.Status == 100">&#xe66d;</i>
                                    <i class="iconfont" style="color: #ABB0BF" v-else>&#xe66f;</i>
                                </div>
                            </a-popover>
                        </div>
                        <div v-show="item.ApproveUserList.length > 5" class="max-num">+{{item.ApproveUserList.length}}</div>
                    </div>
                </div>

                <div style="text-align: right;margin: 20px 0" v-if="pageCount > 10">
                    <a-pagination show-quick-jumper :total="pageCount" @change="_pageChange" />
                </div>
            </div>
        </div>

        <!--<tableHeader :showMOdelTest="showMOdelTest" @getData="_tableGetData"></tableHeader>-->

        <!--添加工单弹框-->
        <a-drawer :title="setId ? '编辑工单' : '创建工单'" :closable="false" placement="right" :visible="addVisible" @close="_addCancelModel" width="800">
            <div style="padding-bottom: 50px;">
                <div class="alert-title">
                    <span></span> 基本信息
                </div>
                <div class="label-title">
                    <span class="weight-add">*</span>
                    <span>工单标题</span>
                </div>
                <div class="input">
                    <a-input style="width: 100%" v-model="orderTitle"></a-input>
                </div>
                <div class="label-title">
                    <span class="weight-add">*</span>
                    <span>工单类型</span>
                </div>
                <div class="input">
                    <a-cascader style="width: 100%" v-model="addSelectType" :options="workOrderTypeList" placeholder="选择类型" @change="_selectTypeChange"/>
                </div>
                <div class="rule-text" v-if="(templateAllData && templateAllData.code != 0) && (templateAllData.info.RuleRemarks || templateAllData.info.Remarks)" style="color: #dd514c">备注：{{templateAllData.info.Remarks || templateAllData.info.RuleRemarks}}</div>
                <!--模板数据-->
                <div v-if="templateInfo && templateInfo.length != 0">
                    <div class="label-title">
                        <span class="weight-add">*</span>
                        <span v-text="templateInfo.Name"></span>
                    </div>
                    <div class="template">
                        <div class="template-item" v-for="item in templateInfo.Field">
                            <span class="item-label">{{item.FieldName}}</span>
                            <div class="item-input">
                                <!--输入框-->
                                <a-input v-if="item.FieldType == 'input'" placeholder="请输入" :disabled="item.readonly && item.readonly == 1 ? true : false" v-model="item.value" />
                                <!--单选框-->
                                <a-radio-group name="radioGroup" v-model="item.value" v-if="item.FieldType == 'radio'" :disabled="item.readonly && item.readonly == 1 ? true :false">
                                    <a-radio :value="tab" v-for="tab in (item.FieldValue.split('|'))">{{tab}}</a-radio>
                                </a-radio-group>
                                <!--多选框-->
                                <a-checkbox-group v-model="item.value" v-if="item.FieldType == 'checkbox'"  :disabled="item.readonly && item.readonly == 1 ? true :false">
                                    <a-checkbox :value="tab" v-for="tab in (item.FieldValue.split('|'))">{{tab}}</a-checkbox>
                                </a-checkbox-group>
                                <!--下拉框-->
                                <a-select style="width: 100%" v-model="item.value" @change="_selectChange(item)" v-if="item.FieldType == 'select'" :disabled="item.readonly && item.readonly == 1 ? true :false">
                                    <a-select-option :value="tab" v-for="tab in (item.FieldValue.split('|'))">{{tab}}</a-select-option>
                                </a-select>
                                <!--日期选择-->
                                <a-date-picker v-model="item.value"  v-if="item.FieldType == 'input-date'" :disabled="item.readonly && item.readonly == 1 ? true :false"/>
                                <!--选人-->
                                <person class="person" v-if="item.FieldType=='choose_user'" v-bind="{rank: 1,nums: 1,department: 1,contacts: '', selectArr: item.value || [], }" @getData="(e)=>{item.value = e}"></person>
                                <!--选部门-->
                                <person class="person" v-if="item.FieldType=='center'" v-bind="{rank: 1,nums: 1,department: 2,contacts: '', selectArr: item.value || [], }" @getData="(e)=>{item.value = e}"></person>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="label-title">
                    <span class="weight-add">*</span>
                    <span>紧急程度</span>
                </div>
                <div class="input">
                    <a-radio-group name="radioGroup" v-model="weight">
                        <a-radio :value="item.value" v-for="(item,index) in baseMsg.LevelText" v-if="index > 0">{{item.name}}</a-radio>
                    </a-radio-group>
                </div>
                <div class="label-title">
                    <span>详细描述</span>
                </div>
                <div class="input" style="width: 700px">
                    <div id="websiteEditorElem" style="width: 100%"></div>
                </div>
                <div class="label-title">
                    <span>添加附件</span>
                </div>
                <div class="input">
                    <div class="upload-img" v-if="(uploadList && uploadList.length != 0) && item.FileType.split('/')[0] == 'image'" v-for="(item,index) in uploadList" @mouseover="hoverImgIndex = index" @mouseout="hoverImgIndex = -1">
                        <img :src="item.FileUrl" alt="">
                        <div class="del-dev" v-show="hoverImgIndex == index">
                            <div class="shade"></div>
                            <a-icon type="delete" class="img-del" @click="uploadList.splice(index,1)"/>
                        </div>
                    </div>

                    <div class="upload-img">
                        <span class="upload-btn">+</span>
                        <input type="file" @change="_uploadCourceIcon($event)">
                    </div>

                    <div class="file-text">
                        <div class="file-item">
                            <div class="file-name" v-if="(uploadList && uploadList.length != 0) && item.FileType.split('/')[0] != 'image'" v-for="(item,index) in uploadList">
                                <a-icon type="paper-clip"/>
                                <span class="file-title">{{item.FileName}}</span>
                                <a-icon type="delete" class="file-del"  @click="uploadList.splice(index,1)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="label-title">
                    <span>指派给</span>
                    <span class="weight-add" style="margin-left: 5px">*</span>
                </div>
                <div class="input" style="width: 100%">
                    <div v-if="approveList && approveList.length != 0">
                        <a-select v-model="item.selectValue" style="width: 120px;margin-right: 15px;" v-for="(item,index) in approveList">
                            <a-select-option :value="tab.Id" v-for="tab in item">{{tab.Name}}</a-select-option>
                        </a-select>
                    </div>
                    <div class="headPortrait" v-else>
                        <div class="itemPortrait" v-if="approveUserList" v-for="(item, index) in approveUserList" :key="index">
                            <img class="img" :src="item.icon || item.Avatar" alt=""/>
                            <div>{{ item.title || item.UserName }}</div>
                            <a-icon class="del-icon" type="close-circle" @click="approveUserList.splice(index,1)"/>
                        </div>
                        <div class="itemPortrait">
                            <i class="iconfont" style="position: relative; top: -10px; font-size: 37px">&#xe676;</i>
                            <div></div>
                            <person class="person" style="overflow: hidden" v-bind="personObj1" @getData="(data)=>{this.approveUserList = data}"></person>
                        </div>
                    </div>
                </div>
                <div class="label-title">
                    <span>抄送给</span>
                    <span class="weight-add" style="margin-left: 5px">*</span>
                </div>
                <div class="input">
                    <div class="headPortrait">
                        <div class="itemPortrait" v-if="sendusersList" v-for="(item, index) in sendusersList" :key="index">
                            <img class="img" :src="item.icon || item.Avatar" alt=""/>
                            <div>{{ item.title || item.UserName }}</div>
                            <a-icon class="del-icon" type="close-circle" @click="sendusersList.splice(index,1)"/>
                        </div>
                        <div class="itemPortrait">
                            <i class="iconfont" style="position: relative; top: -10px; font-size: 37px">&#xe676;</i>
                            <div></div>
                            <person class="person" style="overflow: hidden" v-bind="personObj2" @getData="(data)=>{this.sendusersList = data}"></person>
                        </div>
                    </div>
                </div>
                <div class="alert-footer">
                    <a-button style="margin-right: 25px" @click="_addCancelModel">取消</a-button>
                    <a-button type="primary" @click="_saveOrder">{{setId ? '修改' : '确定'}}</a-button>
                </div>
            </div>
        </a-drawer>
        <!--工单详情-->
        <a-drawer :closable="false" placement="right" :visible="detailVisible"  @close="_detailCancelModel" width="1100" :headerStyle="{padding: '10px 15px'}" :bodyStyle="{padding: '0px',height: '98%'}">
            <div style="height: 100%;" @click="showEmoji = false;">
                <div style="flex: 1;height: 100%;overflow-y: scroll;position: relative" ref="detailRef">
                    <div class="detail-header" v-if="detailData">
                        <div class="flex" style="margin-bottom: 8px;justify-content: flex-start;">
                            <a-tag style="margin-right: 5px;padding:1px 9px" :color="_statusColor(detailData.data.StatusText)">{{detailData.data.StatusText}}</a-tag>
                            <a-tag style="margin-right: 0;padding:1px 9px" :color="_weightColor(detailData.data.Level)">{{detailData.data.Level}}</a-tag>
                            <div class="order-title">{{detailData.data.Name}}</div>
                        </div>
                        <div>
                            <a-popover placement="topLeft">
                                <template slot="content">
                                    <div class="flex" style="padding-right: 20px;">
                                        <img class="approve-icon" :src="detailData.data.Avatar" alt="">
                                        <div>
                                            <div class="approve-name">{{detailData.data.CreateUserName}} <span>{{detailData.data.KeyPostName}}</span></div>
                                            <div class="approve-post">{{detailData.data.ParentName}}</div>
                                        </div>
                                    </div>
                                </template>
                                <img class="user-img" :src="detailData.data.Avatar" alt="">
                            </a-popover>
                            <span class="user-name" v-text="detailData.data.CreateUserName"></span>
                            <span class="create-time" v-text="detailData.data.CreateTime"></span>
                            <span class="department"><span>{{detailData.data.Category}}</span></span>
                        </div>
                        <div class="operation-btn">
                            <a-tooltip>
                                <template slot="title">删除工单</template>
                                <a-popconfirm title="你确定要删除工单吗?" ok-text="确定" cancel-text="取消" @confirm="_operationBtn('删除')">
                                    <div class="btn-icon" v-if="detailData.button.del == '删除'">
                                        <a-icon type="delete" />
                                    </div>
                                </a-popconfirm>
                            </a-tooltip>

                            <a-tooltip>
                                <template slot="title">
                                    编辑工单
                                </template>
                                <div class="btn-icon" v-if="detailData.button.edit == '编辑'" @click="_operationBtn('编辑')">
                                    <a-icon type="form" />
                                </div>
                            </a-tooltip>

                            <a-tooltip>
                                <template slot="title">
                                    创建子工单
                                </template>
                                <div class="btn-icon" v-if="detailData.button.child_task == '创建子工单'" @click="_operationBtn('创建子工单')">
                                    <a-icon type="plus" />
                                </div>
                            </a-tooltip>
                        </div>
                    </div>
                    <div class="detail-content" v-if="detailData">
                        <a-button style="margin-right: 8px;" v-if="item != '创建子工单' && item != '编辑' && item != '删除'" v-for="(item,index) in detailData.button" :type="item == '进度' ? 'primary' : ''" @click="_operationBtn(item)">{{item == '进度' ? '修改进度' : item}}</a-button>
                        <!--修改工单进度-->
                        <div class="set-schedule-modal" v-show="setScheduleModal">
                            <div class="header">{{setOrderType == '评论' ? '评论': '修改进度'}}</div>
                            <div class="content">
                                <div @click="showEmoji2 = false">
                                    <div>
                                        <a-button :type="selectSchedule == item.value ? 'primary' : ''" class="status-btn" v-for="(item,index) in scheduleList" @click="_setOrderStatus(index)">{{item.name}}</a-button>
                                    </div>
                                    <div>
                                        <div class="input-item flex" v-if="selectSchedule == 101" style="margin-top: 15px">
                                            <span style="margin-right: 15px">评分</span>
                                            <div style="flex:1">
                                                <a-rate v-model="Star" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="comment-fixed" style="position: relative;left: initial;bottom: initial;width: 100%;padding: 0;">
                                            <div class="comment-edit" style="position: initial;width: 100%">
                                                <!--编辑框-->
                                                <div class="edit-div" contenteditable="true" id="my-edit2"></div>
                                                <!--附件-->
                                                <div class="commit-file-list" style="overflow-y: scroll;max-height: 140px;padding-bottom: 40px;" v-show="scheduleFileList != '' && scheduleFileList.length != 0">
                                                    <div class="file-item">
                                                        <div class="flex" style="margin-right: 10px;flex-wrap: wrap;justify-content: flex-start;">
                                                            <div class="file-image" v-if="item.FileType.split('/')[0] == 'image'" v-for="(item,index) in scheduleFileList">
                                                                <img :src="item.FileUrl" alt="">
                                                                <a-icon type="delete" class="file-del"  @click="scheduleFileList.splice(index,1)"/>
                                                                <div class="shade"></div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div class="file-name" v-if="item.FileType.split('/')[0] != 'image'" v-for="(item,index) in scheduleFileList">
                                                                <a-icon type="paper-clip"/>
                                                                <span class="file-title">{{item.FileName}}</span>
                                                                <a-icon type="delete" class="file-del"  @click="scheduleFileList.splice(index,1)"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="edit-sub flex" style="width: 99%;left: 50%;transform: translateX(-50%);">
                                                    <div class="edit-icon">
                                                        <ul class="flex">
                                                            <li>
                                                                <a-icon type="link" />
                                                                <span>附件</span>
                                                                <input type="file" class="file-input" multiple  @change="_uploadCourceIcon($event,2)">
                                                            </li>
                                                            <li @click.stop="showEmoji2 = true">
                                                                <a-icon type="smile" />
                                                                <span>表情</span>
                                                                <div class="emoji" v-show="showEmoji2">
                                                                    <span v-for="item in emojiList" @click="_selectEmoji('my-edit2',item)">{{item}}</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <a-button @click="_closeScheduleModal">取消</a-button>
                                    <a-button type="primary" style="margin-left: 15px;" @click="_saveSchedule">确定</a-button>
                                </div>
                            </div>
                        </div>
                        <div class="order-num" v-show="detailData.data.Num">
                            工单号：{{detailData.data.Num}}
                            <a-icon type="copy" @click="_copyOrderNum(detailData.data.Num)"/>
                            <input type="input" style="opacity: 0;"  ref="copyOrderNum" :value="detailData.data.Num">
                        </div>
                        <!--工单内容-->
                        <div class="order-text" @click="_maxImage" v-html="detailData.data.Content"></div>
                        <!--上传的文件-->
                        <div class="file">
                            <div class="file-img" v-if="item.FileType.split('/')[0] == 'image'" v-for="item in (JSON.parse(detailData.data.Attachment))">
                                <img @click="_maxImage($event,item.FileUrl)" :src="item.FileUrl + '?imageView2/1/w/170/h/170'" alt="">
                            </div>
                            <div v-if="item.FileType.split('/')[0] != 'image'" v-for="item in (JSON.parse(detailData.data.Attachment))">
                                <span @click="_windowOpen(item.FileUrl,item)" :href="item.FileUrl" style="color: #1890FF;cursor: pointer"><a-icon type="link" /> {{item.FileName}}</span>
                                <a-icon type="download" class="file-del" style="margin-left: 15px;color: #1890FF" @click="_download(item.FileUrl,item.FileName)"/>
                            </div>
                        </div>
                        <!--模板信息-->
                        <div class="template-data" v-if="detailData.extra && detailData.extra.length !=0" v-for="item in detailData.extra">
                            <div class="table">
                                <div class="table-header">
                                    {{item.Name}} <span v-if="detailData.data.RefundInfo">{{'： '+ detailData.data.RefundInfo.SchoolInfo.OrgPath || ''}}</span>
                                    <!--退费工单才会显示的按钮-->
                                    <span v-if="detailData.data.RefundInfo && ['ef678ed793794d1ff4fcd58d249333a2','80652c8d65b712b76c309c8fba9d4935'].includes(detailData.data.CategoryId)">
                                        <a-button @click="_skipPage('/refundReport')" style="margin-left: 15px;">退费预警</a-button>
                                        <a-button @click="_skipPage('student',detailData.data.RefundInfo.PayInfo.StudentId,detailData.data.RefundInfo.PayInfo.StudentName)" style="margin-left: 15px;">会员信息</a-button>
                                    </span>
                                </div>
                                <div class="table-content" v-for="tab in item.list">
                                    <div class="table-row flex">
                                        <div class="row-label" v-text="tab.FieldName + ' ：'"></div>
                                        <div class="row-text" v-text="tab.ContentName || tab.Content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--退费工单收款信息-->
                        <div class="template-data" style="margin-top: 20px;" v-if="detailData.data.RefundInfo && detailData.data.RefundInfo.PayInfo.Name">
                            <div class="table">
                                <div class="table-header">收款信息：{{detailData.data.RefundInfo.PayInfo.Name}}，{{detailData.data.RefundInfo.PayInfo.BankName}}，{{detailData.data.RefundInfo.PayInfo.BankAccount}}，信息提交人：{{detailData.data.RefundInfo.PayInfo.UpdateUserName}}，提交时间：{{detailData.data.RefundInfo.PayInfo.CreateTime}}</div>
                                <div class="table-content">
                                    <table class="content-table">
                                        <thead>
                                            <tr>
                                                <td>收款人信息</td>
                                                <td>预计付款日期</td>
                                                <td>付款金额</td>
                                                <td>状态</td>
                                                <td>异常信息</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item,index,key) in detailData.data.RefundInfo.PayList">
                                                <td>{{key + 1}}期单据编码：{{item.Code}}</td>
                                                <td>{{item.PlanPayDate}}</td>
                                                <td>{{item.Money}}</td>
                                                <td>{{item.Status}}</td>
                                                <td>{{item.Msg}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!--子工单-->
                        <div class="children-order">
                            <div v-for="item in detailData.data.Children" @click="_getOrderData({Id: item.Id})">
                                <div class="detail-header" style="border-radius: 4px;cursor: pointer">
                                    <div class="flex" style="margin-bottom: 8px;justify-content: flex-start;">
                                        <a-tag style="margin-right: 5px;padding:1px 9px" :color="_statusColor(item.StatusText)">{{item.StatusText}}</a-tag>
                                        <a-tag style="margin-right: 0;padding:1px 9px" :color="_weightColor(item.LevelText)">{{item.LevelText}}</a-tag>
                                        <div class="flex" style="flex: 1">
                                            <div class="order-title">{{item.Name}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="create-time" style="margin-right:8px;">工单创建</span>
                                        <img class="user-img" :src="item.Avatar" alt="">
                                        <span class="user-name" v-text="item.CreateUserName"></span>
                                        <span class="create-time" v-text="item.CreateTime"></span>
                                        <span class="department">工单所属 <span style="padding-left: 10px;">{{item.Category}}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 查看父工单 -->
                        <div v-if="detailData.data.Pid">
                            <a-button type="default" @click="_getOrderData({Id: detailData.data.Pid})">查看父工单</a-button>
                        </div>
                        <!--指派人-->
                        <div class="label-title">
                            <span style="color: #000;font-size: 14px">指派人</span>
                        </div>
                        <div class="input" style="width: 100%">
                            <div class="headPortrait">
                                <div class="itemPortrait" v-if="approveUserList" v-for="(item, index) in approveUserList" :key="index">
                                    <div>
                                        <a-popover placement="topLeft">
                                            <template slot="content">
                                                <div class="flex" style="padding-right: 20px;">
                                                    <img class="approve-icon" :src="item.icon" alt="">
                                                    <div>
                                                        <div class="approve-name">{{item.title}} <span>{{item.KeyPostName}}</span></div>
                                                        <div class="approve-post">{{item.ParentName}}</div>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 10px">
                                                    <a-tag style="margin-right: 5px;padding:1px 9px;display: inline-block;vertical-align: middle;" :color="_statusColor(item.StatusText)">{{item.StatusText}}</a-tag>
                                                    <a-popconfirm title="你确定要催一下吗?" :getPopupContainer="triggerNode => { return triggerNode.parentNode || document.body;}" ok-text="确定" cancel-text="取消" placement="bottom" @confirm="_hurry(item)">
                                                        <div class="hurry" v-if="item.StatusText != '已完成'">催一下</div>
                                                    </a-popconfirm>
                                                </div>
                                            </template>
                                            <div style="display: inline-block;position: relative;">
                                                <img class="img" :src="item.icon || item.Avatar" alt=""/>
                                                <i class="iconfont" style="color: #FF0000" v-if="item.Status == 6">&#xe66e;</i>
                                                <i class="iconfont" style="color: #1890FF" v-else-if="item.Status == 3 || item.Status == 2">&#xe671;</i>
                                                <i class="iconfont" style="color: #52C41A" v-else-if="item.Status == 100">&#xe66d;</i>
                                                <i class="iconfont" style="color: #ABB0BF" v-else>&#xe66f;</i>
                                            </div>
                                        </a-popover>
                                    </div>
                                    <div>{{ item.title || item.UserName }}</div>
                                </div>
                                <div class="itemPortrait">
                                    <img src="https://smart-resource.sikegroup.com/%E7%BB%84%207649.png" style="width: 37px;height: 37px;position: relative; top: -10px;" alt="">
                                    <div></div>
                                    <person class="person" style="overflow: hidden" v-bind="personObj1" @getData="(data)=>{_setApprove(data,'approveUserList')}"></person>
                                </div>
                            </div>
                        </div>
                        <!--抄送人-->
                        <div class="label-title">
                            <span style="color: #000;font-size: 14px">抄送人</span>
                        </div>
                        <div class="input" style="width: 100%">
                            <div class="headPortrait">
                                <div class="itemPortrait" v-if="sendusersList" v-for="(item, index) in sendusersList" :key="index">
                                    <img class="img" :src="item.icon || item.Avatar" alt=""/>
                                    <div>{{ item.title || item.UserName }}</div>
                                </div>
                                <div class="itemPortrait">
                                    <img src="https://smart-resource.sikegroup.com/%E7%BB%84%207649.png" style="width: 37px;height: 37px;position: relative; top: -10px;" alt="">
                                    <div></div>
                                    <person class="person" style="overflow: hidden" v-bind="personObj2" @getData="(data)=>{_setApprove(data,'sendusersList')}"></person>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--跟踪状态-->
                    <div v-if="detailData && detailData.data.RefundInfo" style="margin-bottom: 20px;">
                        <div class="comment-title">跟踪状态</div>
                        <div class="comment-list">
                            <div class="comment-item" v-for="(item,index) in detailData.data.RefundInfo.TrecordList">
                                <div class="comment-header">
                                    <img :src="'http://sk-company-file.sikegroup.com/avatar/'+item.PersonId" class="avatar" alt="">
                                    <span class="name">{{item.PersonName}}</span>
                                    <span class="create-time">{{item.CreateTime}}</span>
                                </div>
                                <div class="comment-content">
                                    <div @click="_maxImage" v-html="item.RecordTit"></div>
                                </div>
                                <div class="line" v-show="index != detailData.data.RefundInfo.TrecordList.length-1"></div>
                            </div>
                        </div>
                    </div>
                    <!--工单动态-->
                    <div>
                        <div class="comment-title">评论</div>
                        <div class="comment-list">
                            <div class="comment-item" v-for="(item,index) in detailData.log">
                                <div class="comment-header">
                                    <a-popover placement="topLeft">
                                        <template slot="content">
                                            <div class="flex" style="padding-right: 20px;">
                                                <img class="approve-icon" :src="item.Avatar" alt="">
                                                <div>
                                                    <div class="approve-name">{{item.CreateUserName}} <span>{{item.KeyPostName}}</span></div>
                                                    <div class="approve-post">{{item.ParentName}}</div>
                                                </div>
                                            </div>
                                        </template>
                                        <img :src="item.Avatar" class="avatar" alt="">
                                    </a-popover>
                                    <span class="name">{{item.CreateUserName}}</span>
                                    <span class="create-time">{{item.CreateTime}}</span>
                                </div>
                                <div class="comment-content">
                                    <div @click="_maxImage" v-html="item.Content"></div>
                                    <div class="comment-file input">
                                        <div class="upload-img" v-if="tab.FileType.split('/')[0] == 'image'" v-for="(tab,index) in JSON.parse(item.Attachment)" >
                                            <img @click="_maxImage($event,tab.FileUrl)" :src="tab.FileUrl + '?imageView2/1/w/170/h/170'" alt="">
                                        </div>
                                        <div>
                                            <div class="file-name" @click="_windowOpen(tab.FileUrl,tab)" v-if="tab.FileType.split('/')[0] != 'image'" v-for="(tab,index) in JSON.parse(item.Attachment)">
                                                <a-icon type="paper-clip" style="color: rgb(41, 148, 255)"/>
                                                <span class="file-title" style="margin: 0 5px;color: rgb(41, 148, 255);cursor: pointer">{{tab.FileName}}</span>
                                                <a-icon type="download" class="file-del" style="margin-left: 15px;color: #1890FF" @click="_download(tab.FileUrl,tab.FileName)"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="line" v-show="index != detailData.log.length-1"></div>
                            </div>
                        </div>

                        <div class="comment-fixed" style="margin-top: 50px;">
                            <div class="comment-edit">
                                <a-popover trigger="click">
                                    <div class="approve-list" slot="content">
                                        <div class="approve-item flex" :class="hurryData.includes(item.id) ? 'active' : ''" v-for="(item,index) in aiteUserList" @click="_addHurry(item.id)">
                                            <img class="approve-icon" :src="item.icon" alt="">
                                            <div class="approve-name">{{item.title}}</div>
                                        </div>
                                    </div>
                                    <div style="padding: 5px;cursor: pointer;display: inline-block">@提及他人 <span style="color: #1890FF;padding-left: 5px;">{{hurryStr}}</span></div>
                                </a-popover>
                                <!--编辑框-->
                                <div class="edit-div" contenteditable="true" :style="commitFileList.length==0?'height: 100px':''" id="my-edit">

                                </div>
                                <!--附件-->
                                <div class="commit-file-list" style="overflow-y: scroll;max-height: 150px;" v-show="commitFileList != '' && commitFileList.length != 0">
                                    <div class="file-item">
                                        <div class="flex" style="margin-right: 10px;flex-wrap: wrap;justify-content: flex-start;">
                                            <div class="file-image" v-if="item.FileType.split('/')[0] == 'image'" v-for="(item,index) in commitFileList">
                                                <img :src="item.FileUrl" alt="">
                                                <a-icon type="delete" class="file-del"  @click="commitFileList.splice(index,1)"/>
                                                <div class="shade"></div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="file-name" v-if="item.FileType.split('/')[0] != 'image'" v-for="(item,index) in commitFileList">
                                                <a-icon type="paper-clip"/>
                                                <span class="file-title">{{item.FileName}}</span>
                                                <a-icon type="delete" class="file-del"  @click="commitFileList.splice(index,1)"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="edit-sub flex">
                                    <div class="edit-icon">
                                        <ul class="flex">
                                            <li>
                                                <a-icon type="link" />
                                                <span>附件</span>
                                                <input type="file" class="file-input" multiple  @change="_commitChange($event)">
                                            </li>
                                            <li @click.stop="showEmoji = true">
                                                <a-icon type="smile" />
                                                <span>表情</span>
                                                <div class="emoji" v-show="showEmoji">
                                                    <span v-for="item in emojiList" @click="_selectEmoji('my-edit',item)">{{item}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="commit-icon" @click="_issueCommit">发布</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--答复会员-->
            <a-drawer :closable="false" title="答复会员" placement="right" width="500" :visible="answerModal" @close="_closeAnswerModal" :bodyStyle="{padding: '10px 20px'}">
                <div>
                    <div class="student-msg-title">注意！注意！注意！答复内容直接通知会员,请谨慎作答！</div>
                    <textarea class="modal-textarea" style="width: 100%" placeholder="请输入内容" v-model="replyContent"></textarea>
                </div>
                <div class="alert-footer">
                    <a-button type="primary" @click="_saveAnswer">确定</a-button>
                    <a-button @click="_closeAnswerModal">取消</a-button>
                </div>
            </a-drawer>
        </a-drawer>
        <!--评价工单-->
        <a-drawer title="评价" :closable="false" placement="right" width="800" @close="_closeEvaluateModal" :headerStyle="{padding: '10px 15px'}" :visible="evaluateVisible">
            <div>
                <div class="flex" style="justify-content: start;margin-bottom: 10px;" v-for="item in approveUserList">
                    <span v-text="item.title"></span>
                    <div style="margin-left: 20px;">
                        <a-radio-group v-model="item.value">
                            <a-radio :value="item.key" v-for="item in evaluateList">{{item.value}}</a-radio>
                        </a-radio-group>
                    </div>
                </div>
                <textarea class="modal-textarea" v-model="evaluateTextarea" placeholder="请输入备注"></textarea>
            </div>
            <div class="alert-footer">
                <a-button type="primary" @click="_saveEvaluate">确定</a-button>
                <a-button @click="_closeEvaluateModal">取消</a-button>
            </div>
        </a-drawer>
        <!--退费工单申请退款-->
        <a-modal v-model="returnVisible" title="填写退费信息" :bodyStyle="{padding: '15px'}" @ok="_returnFn">
            <div class="modal-container" v-if="detailData && detailData.data.RefundInfo">
                <div class="modal-remark">提交前务必先去“费用报销”添加收款人帐号信息。提交后会自动在报销系统生成申请单据，无须再去申请。</div>
                <!--收款人信息-->
                <table class="content-table return-table">
                    <thead>
                        <tr>
                            <td style="width: 100px;">收款人</td>
                            <td>账号</td>
                            <td>银行</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input type="text" v-model="detailData.data.RefundInfo.PayInfo.Name">
                            </td>
                            <td>
                                <input type="text" v-model="detailData.data.RefundInfo.PayInfo.BankAccount">
                            </td>
                            <td>
                                <input type="text" v-model="detailData.data.RefundInfo.PayInfo.BankName">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="content-table return-table">
                    <thead>
                        <tr>
                            <td>分期支付</td>
                            <td>金额</td>
                            <td>预计支付日期</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index,i) in detailData.data.RefundInfo.PayList">
                            <td>{{i+1}}期退费</td>
                            <td>
                                <span v-if="item.Code">{{item.Money}}</span>
                                <input v-else type="text" v-model="item.Money">
                            </td>
                            <td>{{item.PlanPayDate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </a-modal>
        <!--查看大图-->
        <div class="shade" style="position: fixed;top: 0;left:0;width: 100%;height: 100%;background: #000;opacity: .5;z-index: 10000" v-show="showMaxModel" @click="showMaxModel = false;fileShowModel = false"></div>
        <img class="imgLook" v-show="showMaxModel && maxImgUrl != ''" :src="maxImgUrl" />
        <!--查看文件-->
        <div class="fileLook" v-if="fileShowModel" :style="fileMaxUrl.FileType.split('/')[0] == 'application'?'':'background-color: transparent;width: auto;height:auto'">
            <iframe v-if="fileMaxUrl.FileType.split('/')[0] == 'application'" :src="fileMaxUrl.FileType.split('/')[1]=='pdf'?fileMaxUrl.FileUrl: 'https://view.officeapps.live.com/op/view.aspx?src='+fileMaxUrl.FileUrl" frameborder="0"></iframe>
            <audio controls v-else-if="fileMaxUrl.FileType.split('/')[0] == 'audio'" :src="fileMaxUrl.FileUrl"></audio>
            <video v-else controls :src="fileMaxUrl.FileUrl"></video>
        </div>
    </div>
</template>
<script>
    import tableHeader from '../../components/tableHeader/filterBox';
    import Vue from "vue";
    import E from 'wangeditor';
    import person from "@/components/SelectPersonnel/SelectPersonnel";
    import Viewer from "v-viewer"; //图片操作
    import "viewerjs/dist/viewer.css";
    Vue.use(Viewer);
    export default {
        name:'WorkOrder',
        components:{
            tableHeader,E,person
        },
        computed:{
            _selectedKeys(){
                let text = '';
                this.treeData[0].children.map((item)=>{
                    if (item.value == this.selectedKeys[0]){
                        text = item.title;
                    }
                });
                return text;

            },
        },
        data(){
            return {
                userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
                tabData: [],
                showMOdelTest: {
                    show: false,
                    type: 'content',
                    init: ''
                },
                screenInfo: {rank: 1,nums: 100,department: 1,contacts: '', selectArr: [], },
                tabNum: '全部',
                hurryData: [],
                hurryStr: '',
                emojiList: [],
                showEmoji: false,
                showEmoji2: false,
                returnVisible: false,
                KeyWord: '',
                tableColumns: [
                    {
                        dataIndex: 'Level',
                        rowKey: 'Level',
                        width: 100,
                        align: 'center',
                        slots: { title: 'LevelTitle' },
                        scopedSlots: { customRender: 'Level' },
                    },
                    {
                        dataIndex: 'Name',
                        rowKey: 'Name',
                        slots: { title: 'NameTitle' },
                        scopedSlots: { customRender: 'Name' },
                    },
                    {
                        dataIndex: 'StatusText',
                        rowKey: 'StatusText',
                        width: 90,
                        slots: { title: 'StatusTitle' },
                        scopedSlots: { customRender: 'Status' },
                    },
                    {
                        dataIndex: 'Category',
                        rowKey: 'Category',
                        width: 200,
                        slots: { title: 'typeTitle' },
                    },
                    {
                        dataIndex: 'ApproveUsers',
                        rowKey: 'ApproveUsers',
                        width: 180,
                        slots: { title: 'assignTitle' },
                        scopedSlots: { customRender: 'assign' },
                    },
                    {
                        dataIndex: 'CreateUserName',
                        rowKey: 'CreateUserName',
                        width: 100,
                        slots: { title: 'createTitle' },
                    },
                    {
                        dataIndex: 'CreateTime',
                        rowKey: 'CreateTime',
                        width: 180,
                        slots: { title: 'createTimeTitle' },
                    }
                ],
                tableTable: [],
                evaluateList: [
                    {
                        value: '非常满意',
                        key: 1.2
                    },
                    {
                        value: '满意',
                        key: 0.75
                    },
                    {
                        value: '不满意',
                        key: 0.5
                    }
                ],
                weightList: [
                    {
                        name: '普通',
                        value: 1
                    },
                    {
                        name: '一般',
                        value: 2
                    },
                    {
                        name: '很急',
                        value: 3
                    },
                    {
                        name: '非常急',
                        value: 4
                    }
                ],
                statusList: [
                    {
                        name: '未开始',
                        value: 1
                    },
                    {
                        name: '进行中',
                        value: 2
                    },
                    {
                        name: '已完成',
                        value: 3,
                    },
                    {
                        name: '驳回',
                        value: 4
                    },
                    {
                        name: '终结',
                        value: 5
                    }
                ],
                searchList: [],
                aiteUserList: [],
                selectType: '',
                workOrder: '',
                screenModal: false,
                addVisible: false,
                detailVisible: false,
                evaluateVisible: false,
                answerModal: false,
                showMaxModel: false,
                fileShowModel: false,
                fileMaxUrl: '',
                phoneEditor: '',
                phoneEditor2: '',
                addActivity: '',
                addActivity2: '',
                selectedKeys: ['need'],
                treeData: [],
                treeExpandedKeys: [],
                autoExpandParent: true,
                treeReplaceFields:{
                    key: 'value'
                },
                maxImgUrl: '',
                baseMsg: '',
                orderTitle: '',
                detailData: '',
                setId: '',
                Pid: '',
                issueText: '',
                setScheduleModal: false,
                Star: '',
                setOrderType: '',
                evaluateTextarea: '',
                replyContent: '',

                workOrderTypeList: [],
                weight: '',
                pageCount: 0,
                Page: 1,
                Limit: 10,
                Type: '',
                Status: '',
                commitFileList: [],
                scheduleList: [],
                selectSchedule: '',
                scheduleFileList: [],
                scheduleTextarea: '',

                addSelectType: [],
                uploadList: [],
                approveList: [], //指定指派人
                hoverImgIndex: -1,
                approveUserList: [],
                sendusersList: [],
                templateInfo: '',
                templateAllData: '',
                personObj1: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                personObj2: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },

                screenDate: [],
                screenPerson: [],
                screenStatus: 0,
                screenLevel: 0
            }
        },
        mounted(){
            this.emojiList = this.emoji.split(' ');
            //判断是否是退费工单
            if (this.$route.query.StudentId && this.$route.query.CategoryId ){
                this.addSelectType = [this.$route.query.ParentCateogyrId,this.$route.query.CategoryId];
                this._selectTypeChange(this.addSelectType);
                this.$axios.get(8000108,this.$route.query,res=>{
                    this.orderTitle = res.data.data.Name;
                });
                this._addModel();
            }
            //判断是否从企业微信跳过来
            if(this.$route.query.id && this.$route.query.code){
                this._getOrderData({Id:this.$route.query.id});
            }else if(this.$route.query.add == 1 && this.$route.query.code){
                // 是否默认打开编辑框
                this._addModel();
            }else if(this.$route.query.orderType && this.$route.query.code){
                this.treeExpandedKeys = ['my'];
                this.selectedKeys = [this.$route.query.orderType];
            }
            //this.treeExpandedKeys = ['my'];
            this._treeData();
            this._infoData();
            this._listTop();
            //获取工单基本操作信息
            this.$axios.get(8000086,{},res=>{
                this.baseMsg = res.data.data;
            });
            //创建工单分类
            this.$axios.get(8013,{},res=>{
                this.workOrderTypeList = res.data.data;
            });
        },
        methods:{
            _closeText(){
                if (this.KeyWord == '') {
                    this._infoData();
                }
            },
            _skipSkim(id, name) {
                let data = {
                    type: 'student',
                    name: this.userInfo.name,
                    id: this.userInfo.uid
                };
                window.parent.postMessage(data, '*')
            },
            _closeScreen(){
                this.screenDate = [];
                this.screenPerson = [];
                this.$set(this.screenInfo,'selectArr',this.screenPerson);
                this.screenStatus = 0;
                this.screenLevel = 0;
                this._infoData();
            },
            _isScreen(){
                if( this.screenDate.length != 0 || this.screenPerson.length != 0 ||  this.screenStatus != 0 || this.screenLevel != 0){
                    return true;
                }
                return false;
            },
            _screenConfig(){
                this._infoData();
                this.screenModal = false;
            },
            _getScreenInfo(data){
                this.screenPerson = data;
                this.$set(this.screenInfo,'selectArr',data);
            },
            _screeDate(date){
                this.screenDate = [this.__moment__(date[0]).format('YYYY-MM-DD'),this.__moment__(date[1]).format('YYYY-MM-DD')];
            },
            _treeExpand(data){
                this.treeExpandedKeys = data;
                this.autoExpandParent = false;
            },
            _selectChange(data){
                //下拉无法选中
                this.templateInfo.Field.map((res,index)=>{
                    if (res.FieldName == data.FieldName) {
                        this.$set(this.templateInfo.Field,index,data)
                    }
                })
            },
            _returnFn(){
                let data = this.detailData.data.RefundInfo;
                console.log(data);
                let item  = {
                    TaskId: this.setId,
                    PayName: data.PayInfo.Name,
                    BankAccount: data.PayInfo.BankAccount,
                    BankName: data.PayInfo.BankName,
                };

                for(let key in data.PayList){
                    item[key+'_Money'] = data.PayList[key].Money;
                    item[key+'_PlanPayDate'] = data.PayList[key].PlanPayDate;
                }
                console.log(item);
                this.$axios.post(8000110,item,res=>{
                    if (res.data.code == 1) {
                        this.returnVisible = false;
                        this.$message.success(res.data.msg);
                        setTimeout(()=>{
                            this._getOrderData({Id: this.setId});
                        },1500);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _skipPage(url,id,name){
                if(url == 'student'){
                    let data = {
                        type: 'student',
                        name: name,
                        id: id
                    };
                    window.parent.postMessage(data, '*')
                }else{
                    this.$router.push({path: url})
                }
            },
            _addHurry(id,name){
                if (this.hurryData.includes(id)){
                    this.hurryData.splice(this.hurryData.indexOf(id),1)
                }else{
                    this.hurryData.push(id);
                }
                let str = '';
                this.aiteUserList.map(res=>{
                    this.hurryData.map(tab=>{
                        if (res.id == tab){
                            str += '@' + res.title + '，'
                        }
                    })
                });
                str = str.substring(0,str.length-1);
                this.hurryStr = str;
            },
            _hurry(data){
                this.$axios.post(8000107,{
                    TaskId: this.setId,
                    UserId: data.id,
                },res=>{
                    if (res.data.code == 1) {
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _selectEmoji(dom,icon){
                document.getElementById(dom).innerHTML = document.getElementById(dom).innerHTML + icon;
            },
            _setOrderStatus(i){
                this.selectSchedule = this.scheduleList[i].value;
            },
            _copyOrderNum(data){
                this.$refs.copyOrderNum.select();
                document.execCommand("Copy");
                this.$message.success('复制成功');
            },
            _maxImage(e,url){
                e.preventDefault();
                this.maxImgUrl = '';
                if (e.target.src) {
                    this.maxImgUrl = url ? url : e.target.src;
                    this.showMaxModel = true;
                }else if (e.target.href){
                    window.open(e.target.href);
                }
            },
            _windowOpen(url,data){
                if(data.FileType.split('/')[0] == 'text'){
                    window.open(url);
                }else{
                    this.maxImgUrl = '';
                    this.fileMaxUrl = data;
                    this.showMaxModel = true;
                    this.fileShowModel = true;
                }
            },
            _download(url,name){
                //window.location.href = url;
                /**
                 * 获取 blob
                 * @param  {String} url 目标文件地址
                 * @return {cb}
                 */
                function getBlob(url,cb) {
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'blob';
                    xhr.onload = function() {
                        if (xhr.status === 200) {
                            cb(xhr.response);
                        }
                    };
                    xhr.send();
                }

                /**
                 * 保存
                 * @param  {Blob} blob
                 * @param  {String} filename 想要保存的文件名称
                 */
                function saveAs(blob, filename) {
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        var link = document.createElement('a');
                        var body = document.querySelector('body');

                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;

                        // fix Firefox
                        link.style.display = 'none';
                        body.appendChild(link);

                        link.click();
                        body.removeChild(link);

                        window.URL.revokeObjectURL(link.href);
                    };
                }

                /**
                 * 下载
                 * @param  {String} url 目标文件地址
                 * @param  {String} filename 想要保存的文件名称
                 */
                function download(url, filename) {
                    getBlob(url, function(blob) {
                        saveAs(blob, filename);
                    });
                };

                download(url,name);
            },
            _saveAnswer(){
                this.$axios.post(8000100,{
                    TaskId: this.setId,
                    Content: this.replyContent,
                },res=>{
                    if (res.data.code == 1) {
                        this._closeAnswerModal();
                        this._getOrderData({Id: this.setId})
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _closeAnswerModal(){
                this.answerModal = false;
                this.replyContent = '';
            },
            _saveEvaluate(){
                let [Score,noSatisfaction] = ['',false];
                this.approveUserList.map(res=>{
                    Score += res.id + '|' + res.value + ',';
                    if (res.value == 0.5) {
                        noSatisfaction = true;
                    }
                });
                //删除最后一项的逗号
                Score = Score.substring(0,Score.length-1);
                //如果不满意的话最少要填10个字以上
                if (noSatisfaction && this.evaluateTextarea.length < 10){
                    this.$message.error('备注信息最少10个字');
                    return false;
                }
                this.$axios.post(8000099,{
                    TaskId: this.setId,
                    Remark: this.evaluateTextarea,
                    Score: Score,
                },res=>{
                    if (res.data.code == 1) {
                        this._closeEvaluateModal();
                        this._infoData();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _closeEvaluateModal(){
                this.evaluateVisible = false;
                this.evaluateTextarea = '';
            },
            _saveSchedule(){
                if (this.selectSchedule) {
                    this.$axios.post(8000098,{
                        Id: this.setId,
                        Status: this.selectSchedule,
                        StatusText: this.scheduleList.filter((res)=> {return res.value == this.selectSchedule})[0].name,
                        StatusComment: document.getElementById('my-edit2').innerHTML,
                        TaskComment: document.getElementById('my-edit2').innerHTML,
                        Star: this.Star,
                        Attachment: JSON.stringify(this.scheduleFileList)
                    },res=>{
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg);
                            setTimeout(()=>{
                                this._getOrderData({Id: this.setId});
                                this._infoData();
                                this._closeScheduleModal();
                            },1500);
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
            },
            _closeScheduleModal(){
                this.setScheduleModal = false;
                this.selectSchedule = '';
                this.scheduleFileList = [];
                //this.scheduleTextarea = '';
                document.getElementById('my-edit2').innerHTML = '';
                this.setOrderType = '';
            },
            _commitChange(e) {
                this.$message.loading('文件上传中...',0);
                this.__uploadFile__(e.target.files).then(res => {
                    this.$message.destroy();
                    this.commitFileList = this.commitFileList.concat(res);
                })
            },
            _issueCommit(){
                if(document.getElementById('my-edit').innerHTML == '' && this.commitFileList.length == 0){
                    return false;
                }
                this.$axios.post(8000095,{
                    TaskId: this.setId,//        工单id
                    Comment: document.getElementById('my-edit').innerHTML,//        评论内容
                    Attachment: JSON.stringify(this.commitFileList),//        附件
                    UserList: this.hurryData.join(',')
                },res=>{
                    if (res.data.code == 1) {
                        this._getOrderData({Id: this.setId});
                        document.getElementById('my-edit').innerHTML = '';
                        this.commitFileList = [];
                        this.hurryData = [];
                        this.hurryStr = '';
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _operationBtn(type){
                let [url,data] = ['',''];
                this.setOrderType = type;
                if (type == '删除') {
                    url = 8000094;
                    data = {Id: this.setId};
                    this.$axios.post(url,data,res=>{
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg);
                            setTimeout(()=>{
                                this._detailCancelModel();
                                this._infoData();
                            },1500);
                        }else {
                            this.$message.error(res.data.msg);
                        }
                    });
                    return false;
                }else if(type == '评论'){
                    this.$refs.detailRef.scrollTop = this.$refs.detailRef.scrollHeight;
                    document.getElementById("my-edit").focus();
                    return false;
                }else if(type == '答复会员(完成工单)'){
                    this.answerModal = true;
                }else if(type == '进度'){
                    this.setOrderType = type;
                    this.$axios.post(8000097,{
                        TaskId: this.setId
                    },res=>{
                        if (res.data.code==1){
                            this.scheduleList = res.data.data;
                            this.detailData.person.ApproveUsers.map(tab=>{
                                if (tab.id == this.userInfo.uid){
                                    this.selectSchedule = tab.Status;
                                }
                            });
                            console.log(this.selectSchedule);
                        }

                    });
                    this.setScheduleModal = true;
                }else if(type == '创建子工单'){
                    this.Pid = this.setId;
                    this._addModel();
                    this._detailCancelModel();
                    return false;
                }else if(type == '编辑'){
                    let info = this.detailData.data;
                    this.orderTitle = this.detailData.data.Name;
                    this.addSelectType = info.RuleId ? [info.ParentCategoryId,info.CategoryId,info.RuleId] : [info.ParentCategoryId,info.CategoryId];
                    this.weight = String(info.LevelCode);
                    this.addActivity = info.Content;
                    this.approveUserList = this.detailData.person.ApproveUsers;
                    this.sendusersList = this.detailData.person.SendUsers;
                    this.$set(this.personObj1,'selectArr',this.approveUserList);
                    this.$set(this.personObj2,'selectArr',this.sendusersList || []);
                    if (this.detailData.extra.length != 0) {
                        this.detailData.extra[0].Field = this.detailData.extra[0].list;
                        this.templateInfo = JSON.parse(JSON.stringify(this.detailData.extra[0]));
                        this.templateInfo.Field.map(res=>{
                            if (res.FieldType == 'choose_user' || res.FieldType == 'center') {
                                res.value = [{
                                    icon: res.FieldType == 'center' ? 'https://smart-resource.sikegroup.com/user-select-org-1.png' : 'http://sk-company-file.sikegroup.com/avatar/' + res.Content,
                                    title: res.ContentName,
                                    id: res.Content,
                                    isuser: res.FieldType == 'choose_user' ? 1 : 0
                                }]
                            }else if(res.FieldType == 'checkbox'){
                                res.value = res.Content.split('，')
                            }else{
                                res.value = res.Content;
                            }
                        });
                    }
                    this.uploadList = JSON.parse(info.Attachment);
                    this._addModel();
                    this.detailVisible = false;
                    return false;
                }else if (type == '申请付款'){
                    this.returnVisible = true;
                }
                this.$axios.get(url,data,res=>{
                    if (res.data.code == 1) {
                        this.$message.success(res.data.msg);
                        this._detailCancelModel();
                    }else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _setApprove(data,type){
                let [url,info] = [type == 'sendusersList' ? 8000092 : 8000091,{TaskId: this.setId,SendUserInfo: '',ApproveUserInfo: ''}];
                data.map((res,index)=>{
                    info.SendUserInfo += '1|'+ res.id +'|'+res.title+'|1' + (index == data.length - 1 ? '' : ',');
                    info.ApproveUserInfo += '1|'+ res.id +'|'+res.title+'|1' + (index == data.length - 1 ? '' : ',');
                });
                this.$axios.post(url,info,res=>{
                    if (res.data.code == 1) {
                        this[type] = data;
                        this.$message.success(res.data.msg);
                    }else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _saveOrder(){
                let [self,ApproveUsers,SendUsers,data,DataArr] = [this,'','','',[]];
                //指派人id
                //先判断有没有指定的指派人
                if (this.approveList && this.approveList.length != 0) {
                    //判断指派人是否全部选完
                    for(let i = 0 ; i < this.approveList.length; i++){
                        if (!self.approveList[i].selectValue) {
                            self.$message.error('指派人必选');
                            return false;
                        }else {

                            let name = '';
                            self.approveList[i].map(res=>{
                                if(res.Id == self.approveList[i].selectValue){
                                    name = res.Name
                                }
                            });
                            ApproveUsers += '1|'+ self.approveList[i].selectValue +'|'+name+'|1' + (i == self.approveList.length - 1 ? '' : ',');
                        }
                    }
                }else {
                    this.approveUserList.map((res,index)=>{
                        ApproveUsers += '1|'+ res.id +'|'+res.title+'|1' + (index == this.approveUserList.length - 1 ? '' : ',');
                    });
                }
                //抄送人id
                if(this.sendusersList && this.sendusersList.length !=0 ) {
                    this.sendusersList.map((res,index)=>{
                        SendUsers += '1|'+ res.id +'|' +res.title+'|1' + (index == this.sendusersList.length - 1 ? '' : ',');
                    });
                }
                //获取模板输入框数据
                if (this.templateInfo != '' && this.templateInfo.length != 0) {

                    for(let i = 0 ; i < this.templateInfo.Field.length; i++){
                        //模板内容必填
                        if (!self.templateInfo.Field[i].value && self.templateInfo.Field[i].IsRequired == 1) {
                            self.$message.error('模板数据不完整');
                            return false;
                        }
                        let item = '';
                        if(self.templateInfo.Field[i].FieldType == 'checkbox'){
                            //多选按钮中文逗号隔开
                            item = self.templateInfo.Field[i].value.join('，');
                        }else if(self.templateInfo.Field[i].FieldType == 'choose_user' || self.templateInfo.Field[i].FieldType == 'center'){
                            //选人和选部门只需要id
                            item = self.templateInfo.Field[i].value[0].id;
                        }else if(self.templateInfo.Field[i].FieldType == 'input-date'){
                            //时间格式化
                            item = self.__moment__(self.templateInfo.Field[i].value).format('YYYY-MM-DD');
                        } else {
                            item = self.templateInfo.Field[i].value;
                        }

                        DataArr.push(item+"$"+self.templateInfo.Field[i].Id);
                    }
                }
                //提交判断
                if (this.addSelectType.length == 0 || this.weight == -1 || this.orderTitle == '' || ApproveUsers == ''){
                    this.$message.error('必填项未填写');
                    return false;
                }
                this.$message.loading("提交中...");
                let dg = function (data,id) {

                    for(let i = 0; i < data.length;i++){

                        if (data[i].value == id) {

                            return data[i];

                        }else if(data[i].children){

                            let result = dg(data[i].children,id);

                            if (result) {

                                return result;
                            }
                        }
                    }
                };
                data = {
                    Name: this.orderTitle,
                    ParentCategoryId: this.addSelectType[0],       //分类id
                    CategoryId: this.addSelectType[1],        //父集分类id
                    Level: this.weight,         //紧急程度
                    Content: this.addActivity,       //内容
                    RuleId: this.addSelectType.length==3 ? this.addSelectType[2] : '',
                    RuleName: this.addSelectType.length==3 ? dg(this.workOrderTypeList,this.addSelectType[2]).label : '',
                    ApproveUsers: ApproveUsers,        //指派人id 逗号隔开
                    SendUsers: SendUsers, //抄送人id 逗号隔开
                    DataArr: DataArr.length != 0 ? JSON.stringify(DataArr) : '', //模板数据 模板内容数组
                    Pid: this.Pid, //创建子工单
                    Id: this.setId,
                    Attachment: JSON.stringify(this.uploadList)
                };

                this.$axios.post(this.setId ? 8000109 : 8000089,data,res=>{
                    this.$message.destroy();
                    if (res.data.code == 1) {
                        if(this.setId){
                            this.detailVisible = true;
                            this._getOrderData({Id: this.setId});
                        }
                        this._infoData();
                        this._addCancelModel();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _addCancelModel(){
                this.weight = 1;
                this.orderTitle = '';
                this.uploadList = [];
                this.approveList = [];
                this.addSelectType = [];
                this.approveUserList = '';
                this.sendusersList = '';
                this.templateInfo = '';
                this.templateAllData = '';
                document.getElementById('websiteEditorElem').innerHTML = '';
                this.addActivity = '';
                this.Pid = '';
                this.setId = '';
                this.addVisible = false;
            },
            _uploadCourceIcon(e,type) {
                this.$message.loading('文件上传中...',0);
                this.__uploadFile__(e.target.files).then(res => {
                    this.$message.destroy();
                    if (type == 2) {
                        this.scheduleFileList = this.scheduleFileList.concat(res);
                    }else{
                        this.uploadList = this.uploadList.concat(res);
                    }
                    e.target.value = '';
                })
            },
            _selectTypeChange(value,selectedOptions){
                this.addSelectType = value;
                this.approveUserList = [];
                this.sendusersList = [];
                let [item,url] = [{CategoryId: value[1],...this.$route.query},8000087];
                if (value.length == 3) {
                    //获取抄送人和指派人
                    this.$axios.get(url,item,res=>{
                        this.approveUserList =  res.data.approveusers;
                        this.sendusersList = res.data.sendusers;
                        this.$set(this.personObj1,'selectArr',this.approveUserList);
                        this.$set(this.personObj2,'selectArr',this.sendusersList || []);
                        this.templateAllData = res.data;
                        console.log(this.templateAllData);
                    });
                    url = 8000088;
                    item = {Id: value[2]};
                }
                this.$axios.get(url,item,res=>{
                    this.templateInfo = res.data.data;
                    //指定指派人
                    this.approveList = res.data.approve;
                    //第三项没有指派人和抄送人
                    if (value.length == 2) {
                        this.approveUserList =  res.data.approveusers;
                        this.sendusersList = res.data.sendusers;
                        this.$set(this.personObj1,'selectArr',this.approveUserList);
                        this.$set(this.personObj2,'selectArr',this.sendusersList || []);
                        this.templateAllData = res.data;
                        console.log(this.templateAllData);
                    }
                });
            },
            _clickTable(record,index){
                this._getOrderData(record);
            },
            _getOrderData(record){
                this.$message.loading("加载中...");
                let self  = this;
                this.$axios.get(8000090,{Id: record.Id},res=>{
                    this.$message.destroy();
                    if (res.data.code == 1) {
                        this.setId = record.Id;
                        this.detailData = res.data;
                        this.approveUserList = res.data.person.ApproveUsers;
                        this.sendusersList = res.data.person.SendUsers;
                        //@功能包括指派人和抄送人
                        this.aiteUserList = [...this.approveUserList];
                        let createUser = {
                            id: res.data.data.CreateUserId,
                            title: res.data.data.CreateUserName,
                            icon: res.data.data.Avatar,
                        };
                        this.aiteUserList.push(createUser);
                        if(this.sendusersList && this.sendusersList.length != 0){
                            this.aiteUserList = this.aiteUserList.concat(this.sendusersList)
                        }
                        for(let i = 0; i < self.aiteUserList.length;i++){
                            for(let j = i+1;j < self.aiteUserList.length;j++){
                                if (self.aiteUserList[j].id == self.aiteUserList[i].id){
                                    self.aiteUserList.splice(j,1);
                                    break;
                                }
                            }
                        }
                        this.$set(this.personObj1,'selectArr',this.approveUserList);
                        this.$set(this.personObj2,'selectArr',this.sendusersList || []);
                        if (this.detailData.button.evaluate) {
                            this.evaluateVisible = true;
                        }else{
                            this.detailVisible = true;
                        }
                        setTimeout(function () {
                            self.phoneEditor2 = new E('#websiteEditorElem2');
                            self.phoneEditor2.customConfig.menus = [
                                'emoticon'
                            ];
                            self.phoneEditor2.customConfig.emotions = [
                                {
                                    title: '默认',  // tab 的标题
                                    type: 'emoji', // 'emoji' / 'image'
                                    // emoji 表情，content 是一个数组即可
                                    content: self.emoji.split(/\s/),
                                }
                            ];
                            self.phoneEditor2.customConfig.onchange = function (html) {
                                self.addActivity2 = html;
                            };
                            // 创建一个富文本编辑器
                            self.phoneEditor2.create();
                            self.phoneEditor2.txt.html(self.addActivity2);
                        });
                    }
                });
            },
            _pageChange(num){
                this.Page = num;
                this._infoData();
            },
            _infoData(searchType){
                this.$message.loading("加载中...");
                if (searchType == 1) {
                    this.KeyWord = '';
                    this.Status = '';
                    this.tabNum = '全部';
                }
                let [level,date,title,createUserId,ApproveUserId,type] = ['','','','','',''];
                const filterFn = (name) => {
                    return this.searchList.filter(res => {return res.name == name});
                };
                status = this.screenStatus == 0 ? '' : this.baseMsg.StatusText[this.screenStatus].value;
                level = this.screenLevel == 0 ? '' : this.baseMsg.LevelText[this.screenLevel].value;
                title = filterFn('工单标题').length == 0 ? '' : filterFn('工单标题')[0].value;
                type = filterFn('类型').length == 0 ? '' : filterFn('类型')[0].value;
                this.screenPerson.map((res,index)=>{
                    createUserId += res.id +','
                });
                filterFn('指派人').length != 0 && filterFn('指派人')[0].value.map((res,index)=>{
                    ApproveUserId += res.id +','
                });
                this.$axios.get(8000084,{
                    Type: this.selectedKeys[0],
                    Page: this.Page,
                    Limit: this.Limit,
                    Status: status,
                    CategoryId: type[1],
                    ParentCategoryId: type[0],
                    Level: level,
                    StartDate: this.screenDate.length == 0 ? '' : this.screenDate[0],
                    EndDate: this.screenDate.length == 0 ? '' : this.screenDate[1],
                    KeyWord: this.KeyWord,
                    Title: title,
                    CreateUserId: createUserId.substring(0,createUserId.length-1),
                    ApproveUserId: ApproveUserId.substring(0,ApproveUserId.length-1),
                },res=>{
                    if (res.data.code == 0){
                        this.tableTable = [];
                        this.pageCount = 0;
                        this.$message.destroy();
                        return false;
                    }
                    this.pageCount = res.data.count;
                    res.data.data.map(item=>{
                        item.key = item.Id;
                    });
                    this.tableTable = res.data.data;
                    this.$message.destroy();
                })
            },
            _listTop(){
                this.$axios.get(8000085,{
                    Type: this.selectedKeys[0],
                    StartDate: '',
                    EndDate: '',
                    KeyWord: this.KeyWord,

                },res=>{
                    this.tabData = res.data.data;
                })
            },
            _cutTab(e){
                this.Page = 1;
                if (e == '其他'){
                    this.Status = e;

                }else {
                    this.baseMsg.StatusText.map(res=>{
                        if (res.name == e){
                            this.Status = res.value;
                        }
                    });
                }
                this._infoData();
            },
            _treeSelect(selectedKeys){
                this.selectedKeys = [selectedKeys];
                this.Page = 1;
                this._infoData(1);
                // this._listTop();
            },
            _treeData(){
                this.$axios.get(8000083,{},res=>{
                    this.treeData = res.data.data;
                    console.log(this.treeData);
                })
            },
            _detailCancelModel(){
                this.approveUserList = [];
                this.sendusersList = [];
                this.hurryData = [];
                this.hurryStr = '';
                this.$set(this.personObj1,'selectArr',this.approveUserList);
                this.$set(this.personObj2,'selectArr',this.sendusersList);
                document.getElementById('my-edit').innerHTML = '';
                this.commitFileList = [];
                this.setId = '';
                this.$refs.detailRef.scrollTop = 0;
                this.detailVisible = false;
                this.setScheduleModal = false;
            },

            _addModel(){
                let self = this;
                this.$axios.get(8000096,{},res=>{
                    if(res.data.code==1){
                        this.selectedKeys = ['comment'];
                        this._infoData();
                        setTimeout(()=>{
                            this.$message.error(res.data.msg);
                        },300)
                    }else{
                        setTimeout(function () {
                            self.phoneEditor = new E('#websiteEditorElem');
                            self.phoneEditor.customConfig.menus = [
                                'head',
                                'bold',
                                'italic',
                                'underline',
                                'foreColor',  // 文字颜色
                                'backColor',  // 背景颜色
                                'list',
                                'table',
                                'link',
                                'justify',
                                'emoticon'
                            ];
                            self.phoneEditor.customConfig.emotions = [
                                {
                                    title: '默认',  // tab 的标题
                                    type: 'emoji', // 'emoji' / 'image'
                                    // emoji 表情，content 是一个数组即可
                                    content: self.emoji.split(/\s/),
                                }
                            ];
                            self.phoneEditor.customConfig.onchange = function (html) {
                                self.addActivity = html;
                            };
                            // 创建一个富文本编辑器
                            self.phoneEditor.create();
                            self.phoneEditor.txt.html(self.addActivity);
                        });
                        self.addVisible = true;
                    }
                });
            },
            _tabClose(i){
                this.searchList.splice(i,1);
                this._infoData();
            },
            _tableGetData(val){
                let isNew = true;
                this.searchList.map(res=>{
                    if (res.name==this.selectType){
                        res.value = val;
                        isNew = false;
                    }
                });
                if(isNew){
                    let item = {name: this.selectType,value: val,};
                    if(this.showMOdelTest.type == 'cascader'){
                        this.showMOdelTest.list.map(res=>{
                            if(res.value == val[0]){
                                item.key = res.label;
                                res.children.map(tab=>{
                                    if(tab.value == val[1]){
                                        item.key += '->' + tab.label;
                                        if (val.length == 3) {
                                            tab.children.map(tab2=>{
                                                if(tab2.value == val[2]){
                                                    item.key += '->' + tab2.label;
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        })
                    }else{
                        //如果是下拉选择的显示的key
                        if (this.showMOdelTest.type == 'select') {
                            item = {name: this.selectType,value: val,key: this.showMOdelTest.list.filter(res=> { return res.value == val })[0].name};
                        }
                    }
                    this.searchList.push(item)
                }
                this._infoData();
            },
            //工单状态颜色
            _statusColor(i){
                if(i=='进行中'){
                    return '#2994FF';
                }else if(i=='已完成'){
                    return '#75C255';
                }else if(i=='驳回'){
                    return '#FF0000';
                }else if(i == '已终结'){
                    return '#2D3E5D';
                }else if(i == '已撤回'){
                    return '#798CAC'
                }else if(i=='已接收'){
                    return '#49E1E1'
                }else{
                    return '#ABB0BF';
                }
            },
            //紧急程度颜色
            _weightColor(i){
                if(i=='一般'){
                    return 'blue';
                }else if(i=='很急'){
                    return 'orange';
                }else if(i=='非常急'){
                    return 'red';
                }else{
                    return '';
                }
            },
            _showMOdelTest(type,e,list,txt){
                this.selectType = txt;
                let init = '';
                this.searchList.map(res=>{
                    if(res.name==txt){
                        init = res.value;
                    }
                });
                this.showMOdelTest.list = list;
                this.showMOdelTest.type = type;
                this.showMOdelTest.show = true;
                this.showMOdelTest.init = init;
                this.showMOdelTest.event = e;
            },
        }
    }

</script>
<style lang="less" scoped>
    @import "./WorkOrder.less";
</style>
